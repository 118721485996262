import {Component, Input} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../../shared/pipes/safe/safe.pipe';



@Component({
  selector: 'app-empty-result',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule, SafePipe],
  templateUrl: './empty-result.component.html',
  styleUrl: './empty-result.component.scss'
})
export class EmptyResultComponent {
  @Input() image = '/assets/images/empty-box.png';
  @Input() imageWidth = 244;
  @Input() imageHeight = 197;
  @Input() title = 'COMMON.NOT_FOUND';
  @Input() description = 'COMMON.CHANGE_FILTERS';
}
