<div class="result">
  <img class="image"
       [ngSrc]="image"
       [width]="imageWidth"
       [height]="imageHeight"
       [alt]="title | translate"
       priority="true"
  >

  <h1 class="h1">
    {{ title | translate}}
  </h1>

  <div class="description" [innerHTML]="description | translate | safe: 'html'">
  </div>
</div>
